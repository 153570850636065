<template>
  <div class="pa-2">


<!-- <v-card  >
   <v-card-text class="text-center pa-1 ml-10" >
     <v-btn  @click="btnsetup" :color="btnsetupcolor">Setup</v-btn>
      <v-btn class="pa-1 ml-5"   @click="btnsangeet" :color="btnsangeetcolor">Sangeet</v-btn>
      <v-btn class="pa-1 ml-5"  @click="btnwedding" :color="btnweddingcolor">Wedding</v-btn>
   </v-card-text>
  
  </v-card> -->

       <v-card flat class="pa-1">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        :loading="loadingstatus"
        loading-text="Loading... Please wait"
      >
      
        <template v-slot:top>
          <v-card flat>
            <v-card-actions class="pa-0">
              <v-btn disabled  @click="btnall" :color="btnallcolor">
                <v-icon left>mdi-check-all</v-icon>
                All</v-btn>
              <v-btn  @click="btnsetup" :color="btnsetupcolor">
                <v-icon left>mdi-hammer-wrench</v-icon>
                Setup</v-btn>

              
      <v-btn     @click="btnsangeet" :color="btnsangeetcolor">
        <v-icon left>mdi-home-silo-outline</v-icon>
        Event</v-btn>

      
              <v-spacer></v-spacer>
              <div style="width: 400px" class="pt-1 pb-2">
                <v-text-field
                  v-model="search"
                  dense
                  solo-inverted
                  flat
                  label="Search"
                  hide-details
                ></v-text-field>
              </div>
              <export-excel
                v-if="desserts.length > 0"
                :data="desserts"
                type="button"
                class="pt-1 pb-2 pl-2"
              >
                <v-btn color="success"
                  ><v-icon left>mdi-microsoft-excel</v-icon>DOWNLOAD</v-btn
                >

                
              </export-excel>
            </v-card-actions>
          </v-card>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td class="tdalign">{{ item.s_no }}</td>
            <td>{{ item.company_name }}</td>
            <td class="tdalign">{{ item.Limit }}</td>
            <td
              class="tdalign"
            >
              {{ item.Total }}
            </td>

            <td
              class="tdalign u"
              @click="
                editItem(item.Pending, 10, item.vendor_id, item.company_name)
              "
              v-if="$store.state.eventInfo.approvallevel == 2"
            >
              {{ item.Pending }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Pending, 8, item.vendor_id, item.company_name)
              "
              v-else
            >
              {{ item.Pending }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Checked, 8, item.vendor_id, item.company_name)
              "
              v-if="$store.state.eventInfo.approvallevel == 2 && $store.state.eventInfo.UType == 'SubAdmin'"
            >
              {{ item.Checked }}
            </td>

            <td
              class="tdalign u"
              @click="
                editItem(item.Amended, '4', item.vendor_id, item.company_name)
              "
            >
              {{ item.Amended }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Rejected, '6', item.vendor_id, item.company_name)
              "
            >
              {{ item.Rejected }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Approved, '3', item.vendor_id, item.company_name)
              "
            >
              {{ item.Approved }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Printed, '7', item.vendor_id, item.company_name)
              "
            >
              {{ item.Printed }}
            </td>
            <td
              class="tdalign u"
              @click="
                editItem(item.Cancelled, '5', item.vendor_id, item.company_name)
              "
            >
              {{ item.Cancelled }}
            </td>
            <!-- <td class="tdalign">{{ item.Total }}</td> -->
            <td class="tdalign">{{ item.Reprinted }}</td>
          </tr>
        </template>

        <template slot="body.append">
          <tr class="pink--text">
            <td colspan="2">Total</td>
            <td class="tdalign">{{ sumField("Limit") }}</td>
            <td class="tdalign">{{ sumField("Total") }}</td>
            <td class="tdalign">{{ sumField("Pending") }}</td>
            <td
              class="tdalign"
              v-if="$store.state.eventInfo.approvallevel == 2 && $store.state.eventInfo.UType == 'SubAdmin'"
            >
              {{ sumField("Checked") }}
            </td>
            <td class="tdalign">{{ sumField("Amended") }}</td>
            <td class="tdalign">{{ sumField("Rejected") }}</td>
            <td class="tdalign">{{ sumField("Approved") }}</td>
            <td class="tdalign">{{ sumField("Printed") }}</td>
            <td class="tdalign">{{ sumField("Cancelled") }}</td>

            <td class="tdalign">{{ sumField("Reprinted") }}</td>
          </tr>
        </template>

        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          No Record(s)
        </template>
      </v-data-table>
    </v-card>




    <v-snackbar v-model="snackbar" shaped top :color="snackbarcolor">
      {{ snackbartext }}
      <v-btn color="indigo" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
//import { mapMutations } from 'vuex'
//import axios from "axios";
export default {
  components: {},
  data() {
    return {
      btnallcolor:'gray',
    btnsetupcolor:'blue',
    btnsangeetcolor:'gray',
    
    event_type:1,
      date: new Date().toISOString().substr(0, 10),
      time: "",
      datebulk: new Date().toISOString().substr(0, 10),
      timebulk: "",
      modal2: false,
      modal: false,

      modaldatebulk: false,
      modaltimebulk: false,
      menu: false,
      menu2: false,
      snackbar: false,
      snackbartext: "",
      snackbarcolor: "primary",
      EntryMode: "",
      valid: false,
      validbulk: false,
      mode: "NEW",
      newmode: true,
      sheet: false,
      sheetbulk: false,
      search: "",
      loadingstatus: false,
      cardID: "",
      Selcompanytype: "",
      event_id: "",
      //companytype: [],
      companytype_id: [],
      postBody: {
        company_name: "",
        event_id: "",
        venue_id: "",
        vendor_name: "",
        vendor_email: "",
        no_of_application: "",
        deadline: "",
        companytype_id: [],
        Vendor_ID_Update: "",
      },

      headers: [],
      desserts: [],
    };
  },
  mounted() {
    sessionStorage.COMPANYID = "";
    sessionStorage.STATUSID = "";
    // sessionStorage.display_event_name = "IPL T20 2021";
    // sessionStorage.display_venue_name = "Chinaswamy Stadium";

    sessionStorage.EVENTID = "1";
    sessionStorage.VENUEID = "1";

    this.$store.commit("setPageTitle", "Application Summary");
    this.$store.commit("setPageTitleIcon", "mdi-chart-box-outline");
    //this.$store.commit("setEventName", sessionStorage.display_event_name);
    //this.$store.commit("setVenueName", sessionStorage.display_venue_name);
    this.setHeaders();
    this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId,
      this.event_type
    );
  },
  methods: {
    showSnackbar: function (scolor, stext) {
      this.snackbar = true;
      this.snackbartext = stext;
      this.snackbarcolor = scolor;
    },

       btnall:function(){
      //alert("Setup");
      this.btnsetupcolor="gray";
      this.btnsangeetcolor="gray";
      this.btnallcolor="blue";
      this.event_type=0;

       this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId,
      this.event_type
    );},

    btnsetup:function(){
      //alert("Setup");
      this.btnsetupcolor="blue";
      this.btnsangeetcolor="gray";
      this.btnallcolor="gray";
      this.event_type=1;

       this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId,
      this.event_type
    );
    
    },
      btnsangeet:function(){
       this.btnsetupcolor="gray";
      this.btnsangeetcolor="blue";
        this.btnallcolor="gray";
      this.event_type=2;

       this.Reload(
      this.$store.state.eventInfo.EventId,
      this.$store.state.eventInfo.VenueId,
      this.event_type
    );
    },
   



    editItem(item, pending, company_id, company_name) {
      sessionStorage.COMPANYID = company_id;
      sessionStorage.COMPANYNAME = company_name;
       sessionStorage.STATUSID = pending;
        sessionStorage.ETID=this.event_type;
        //alert("check" + this.event_type);

      if(this.$store.state.eventInfo.UType=="Admin" && pending==10 && this.event_type==2)
      {
      //  alert("if"+ pending + "ety-" + this.event_type);
        sessionStorage.STATUSID = 2;
        sessionStorage.ETID=2;
      }

     else if(this.$store.state.eventInfo.UType=="Admin" && pending==10 && this.event_type==1)
      {
       // alert("if"+ pending + "ety-" + this.event_type);
        sessionStorage.STATUSID = 2;
        sessionStorage.ETID=1;
      }
    //  else if(this.$store.state.eventInfo.UType=="SubAdmin" && pending==7 && this.event_type==1)
    //   {
    //    // alert("if"+ pending + "ety-" + this.event_type);
    //     sessionStorage.STATUSID = 7;
    //     sessionStorage.ETID=1;
    //   }
 else if(this.$store.state.eventInfo.UType=="SubAdmin" && pending==8 && this.event_type==1)
      {
       // alert("if"+ pending + "ety-" + this.event_type);
        sessionStorage.STATUSID = 2;
        sessionStorage.ETID=1;
      }

      //  else if(this.$store.state.eventInfo.UType=="SubAdmin" && pending==8 && this.event_type==2)
      // {
      //  // alert("if"+ pending + "ety-" + this.event_type);
      //   sessionStorage.STATUSID = 8;
      //   sessionStorage.ETID=2;
      // }

      

      //  else if(this.$store.state.eventInfo.UType=="Admin" && pending==7 && this.event_type==2)
      // {
      //  // alert("if"+ pending + "ety-" + this.event_type);
      //   sessionStorage.STATUSID = 7;
      //   sessionStorage.ETID=2;
      // }

      //   else if(this.$store.state.eventInfo.UType=="Admin" && pending==3 && this.event_type==2)
      // {
      //  // alert("if"+ pending + "ety-" + this.event_type);
      //   sessionStorage.STATUSID = 3;
      //   sessionStorage.ETID=2;
      // }

      else{
      //  alert("else"+ pending + "ety-" + this.event_type);
        sessionStorage.STATUSID = pending;
       // sessionStorage.ETID=1;
      }
      
      
//alert(this.event_type);
      // this.$router.push("appsummarydetail");
      this.$router.push("appreport");
    },

    sumField(key) {
      // sum data in give key (property)
      return this.desserts.reduce((a, b) => a + (b[key] || 0), 0);
    },

    setHeaders() {
      if (this.$store.state.eventInfo.approvallevel == 2) {
        if (this.$store.state.eventInfo.UType == "SubAdmin") {
        this.headers = [
          {
            text: "ID",
            align: "center",
            value: "vendor_id",
            class: "blue lighten-5",
          },
          {
            text: "Company",
            value: "company_name",
            align: "left",
            class: "blue lighten-5",
          },
          {
            text: "Limit",
            value: "Limit",
            align: "right",
            class: "blue lighten-5",
            sortable: true,
          },
          {
            text: "Received",
            value: "Total",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Pending",
            value: "Pending",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Checked",
            value: "Checked",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Amend",
            value: "Amended",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Rejected",
            value: "Rejected",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Approved",
            value: "Approved",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Printed",
            value: "Printed",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Cancelled",
            value: "Cancelled",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Reprinted",
            value: "Reprinted",
            align: "right",
            class: "blue lighten-5",
          },
        ];
        }
        else{
          this.headers = [
          {
            text: "ID",
            align: "center",
            value: "vendor_id",
            class: "blue lighten-5",
          },
          {
            text: "Company",
            value: "company_name",
            align: "left",
            class: "blue lighten-5",
          },
          {
            text: "Limit",
            value: "Limit",
            align: "right",
            class: "blue lighten-5",
            sortable: true,
          },
          {
            text: "Received",
            value: "Total",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Pending",
            value: "Pending",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Amend",
            value: "Amended",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Rejected",
            value: "Rejected",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Approved",
            value: "Approved",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Printed",
            value: "Printed",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Cancelled",
            value: "Cancelled",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Reprinted",
            value: "Reprinted",
            align: "right",
            class: "blue lighten-5",
          },
        ];
        }
      } else {
        this.headers = [
          {
            text: "ID",
            align: "center",
            value: "vendor_id",
            class: "blue lighten-5",
          },
          {
            text: "Company",
            value: "company_name",
            align: "left",
            class: "blue lighten-5",
          },
          {
            text: "Limit",
            value: "Limit",
            align: "right",
            class: "blue lighten-5",
            sortable: true,
          },
          {
            text: "Received",
            value: "Total",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Pending",
            value: "Pending",
            class: "blue lighten-5",
            align: "right",
            sortable: true,
          },
          {
            text: "Amend",
            value: "Amended",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Rejected",
            value: "Rejected",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Approved",
            value: "Approved",
            align: "right",
            class: "blue lighten-5",
          },
          {
            text: "Printed",
            value: "Printed",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Cancelled",
            value: "Cancelled",
            align: "right",
            class: "blue lighten-5",
          },

          {
            text: "Reprinted",
            value: "Reprinted",
            align: "right",
            class: "blue lighten-5",
          },
        ];
      }
    },

    Reload: async function (event_id, venue_id,etId) {
      // alert(this.$store.state.eventInfo.EventAdminID);
      //alert("hi");
      this.loadingstatus = true;
      await this.$axios
        .get(
          "EventDashboard/applicationSummary/" +
            event_id +
            "/" +
            venue_id +
            "/" +
            this.$store.state.eventInfo.EventAdminID + "/" + etId
        )
        .then((res) => {
          //console.log(res.data.result);
          this.desserts = res.data.result;
          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },

    close() {
      this.sheet = false;
      //  this.Reload();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.tdalign {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
}
.u {
  text-decoration: underline;
  cursor: pointer;
}
</style>

